<template>
    <div
        class="row justify-center align-center header-style pt-5"
        style="height: 35%"
       
    >
        <div>
            <div class="column justify-center align-center">
                <img
                    src="../assets/images/sonphat.png"
                    alt=""
                    style="width: 80px; height: 80px"
                     :class="getManHinh == 'ngang' ? 'mt-4' : '' "
                />
                <div class="font-20 font-bold pt-2">Sơn Phát C&T</div>
            </div>
        </div>
    </div>
    <div style="margin-left: 150px; margin-right: 150px">
        <div class="container-md" style="height: 55%" :style="getManHinh == false ? 'width: 65%' : '' ">
            <DxValidationGroup ref="formValidation">
                <div class="subtitle font-bold">Thông tin đăng nhập</div>
                <div class="label-box">
                    <DxTextBox
                        v-model="model.TaiKhoan"
                        validationMessageMode="always"
                        styling-mode="outlined"
                        mode="email"
                        :tabIndex="1"
                        ref="refTab1"
                        :onEnterKey="onEnterKey"
                        ><DxValidator>
                            <DxRequiredRule
                                message="Tài khoản không được bỏ trống!"
                            /> </DxValidator
                    ></DxTextBox>
                    <div class="label">Tài khoản</div>
                </div>
                <div class="label-box mt-5">
                    <DxTextBox
                        v-model="model.MatKhau"
                        validationMessageMode="always"
                        :mode="pmMatKhau"
                        styling-mode="outlined"
                        class="matkhau-style"
                        :validationStatus="success ? 'valid' : 'invalid'"
                        :onEnterKey="dangNhap"
                        ref="refTab2"
                        :tabIndex="2"
                        ><DxTextBox-DxButton
                            :options="pbMatKhau"
                            name="password"
                            location="after" /><DxValidator>
                            <DxRequiredRule
                                message="Mật khẩu không được bỏ trống!"
                            /> </DxValidator
                    ></DxTextBox>
                    <div class="label">Mật khẩu</div>
                </div>
                <div class="font-12 danger--text pt-1" v-if="!success">
                    Mật khẩu không đúng!
                </div>
            </DxValidationGroup>
            <div class="column align-center justify-space-around mt-4">
                <DxButton
                    id="btn-dangnhap"
                    text="Đăng nhập"
                    type="default"
                    styling-mode="contained"
                    @click="dangNhap"
                />
                <router-link
                    to="/quen-mat-khau"
                    style="color: black; text-decoration: underline"
                    class="pt-2"
                    >Quên mật khẩu?</router-link
                >
                <div class="primary--text mr-3 pt-2">Đăng ký</div>
            </div>
        </div>
    </div>
</template>

<script>
import { DxRequiredRule } from "devextreme-vue/data-grid";
import { mapActions } from "vuex";
import {
    DxTextBox,
    DxValidationGroup,
    DxValidator,
    DxButton,
} from "devextreme-vue";
export default {
    IsFreePage: true,
    components: {
        DxValidationGroup,
        DxTextBox,
        DxValidator,
        DxRequiredRule,
        DxButton,
    },
    data() {
        return {
            firstTime: null,
            time: null,
            success: true,
            model: {
                TaiKhoan: "",
                MatKhau: "",
            },
            pmMatKhau: "password",
            pbMatKhau: {
                icon: "mdi mdi-eye",
                type: "default",
                onClick: () => {
                    this.pmMatKhau =
                        this.pmMatKhau === "text" ? "password" : "text";
                },
            },
        };
    },
    computed: {
        percent() {
            return this.firstTime == this.time ? 0 : 100 / (this.time / 500);
        },
        getManHinh(){
            return this.$store.state.ManHinh.manHinh;
        }
    },
    methods: {
        ...mapActions("idleTime", ["startIdleTimer"]),
        ...mapActions("trangChu", ["layCauHinhChung", "layThongTinBenXe"]),
        async dangNhap() {
            try {
                let valid = this.$refs.formValidation.instance.validate();
                if (!valid.isValid) {
                    return;
                }
                let rs = await this.$Core.AuthApi.Login(
                    this.model.TaiKhoan,
                    this.model.MatKhau
                );
                if (!rs) {
                    this.success = false;
                    return;
                }
                this.layCauHinhChung();
                this.layThongTinBenXe();
                this.$router.push("/");
            } catch (error) {
                console.log(
                    "🚀 ~ file: dang-nhap.vue ~ line 147 ~ dangNhap ~ error",
                    error
                );
            }
        },
        onEnterKey(e) {
            let index = e.event.currentTarget.tabIndex;
            if (index) {
                let ref = this.$refs["refTab" + (index + 1)];
                ref ? ref.instance.focus() : null;
            }
        },
    },
    mounted() {
        let refSdt = this.$refs.refTab1.instance;
        if (refSdt) {
            refSdt.focus();
        }
    },
};
</script>
<style scoped>
>>> .matkhau-style .dx-button.dx-button-default .dx-icon {
    color: black !important;
}
</style>
<style lang="scss" scoped>
.header-style {
    width: 100%;
    background: linear-gradient(to bottom, #08a9f278, rgb(3 169 244 / 2%));
}
</style>